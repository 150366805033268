import { EViewItemListRoutes, EViewItemListSections } from "@enums/googleAnalyticsV4.enum";
import { extractRouteFromRouter, getQueyParams } from "@utilsurl";
import { NextRouter } from "next/router";

export const viewItemListSectionFromRoute = (router: NextRouter, selectedJob?: boolean) => {
  const { route, query } = router;

  const querySearch = getQueyParams("q", query);

  const cleanRoute = extractRouteFromRouter(route);

  if (cleanRoute === EViewItemListRoutes.empleos) {
    if (selectedJob && !query.jobSlug) {
      return EViewItemListSections.vacante;
    }
    if (selectedJob && query.jobSlug) {
      return EViewItemListSections.vacantePage;
    }
    return EViewItemListSections.vitrinaSearch;
  }

  if (cleanRoute === EViewItemListRoutes.empresas) {
    if (selectedJob && !query.jobSlug) {
      return EViewItemListSections.jobsiteVacante.replace("{company_name}", query.companySlug as string);
    }
    if (selectedJob && query.jobSlug) {
      return EViewItemListSections.jobsiteVacantePage.replace("{company_name}", query.companySlug as string);
    }
    if (querySearch) {
      return EViewItemListSections.jobsiteVitrinaSearch.replace("{company_name}", query.companySlug as string);
    }
    if (route.includes(EViewItemListRoutes.empleos)) {
      return EViewItemListSections.jobsiteVitrinaCategory.replace("{company_name}", query.companySlug as string);
    }

    return EViewItemListSections.jobsiteHome.replace("{company_name}", query.companySlug as string);
  }

  if (cleanRoute === EViewItemListRoutes.trabajos) {
    return EViewItemListSections.vitrinaCategory;
  }

  return "";
};
