export enum ECarouselType {
  testimonies = "testimonies",
  vacant = "vacant"
}

export enum EPageType {
  mainPage = "mainPage",
  jobDetailPage = "jobDetailPage",
  jobsitePage = "jobsitePage",
  companyPage = "companyPage",
  worksPage = "worksPage"
}

export enum EJobsitesVisibility {
  privateJobsite,
  publicJobsite
}
