export const serverSideDataLayer = {
  push: (...events: unknown[]): boolean => {
    console.warn(
      "[GOOGLE-TAG-MANAGER]",
      "Invalid tracking, google tag manager events must be sent from the browser. You are sending it from the server side."
    );
    console.error(new Date(), events);
    return true;
  }
};
