import { ISubject } from "@interfaces/subject.interface";
import { IObserver } from "@interfaces/observer.interface";

export class RevokeChallengeSubject implements ISubject {
  public challenge: string | null;
  private observers: IObserver[] = [];

  constructor() {
    this.challenge = null;
  }

  public attach(observer: IObserver): void {
    const isExist = this.observers.includes(observer);
    if (isExist) return;
    this.observers.push(observer);
  }

  public detach(observer: IObserver): void {
    const observerIndex = this.observers.indexOf(observer);
    if (observerIndex === -1) return;
    this.observers.splice(observerIndex, 1);
  }

  public notify(): void {
    for (const observer of this.observers) {
      observer.update(this);
    }
  }
  public setChallenge(challenge: string | null): void {
    this.challenge = challenge;
  }

  public revokeChallenge(challenge: string | null): void {
    if (this.challenge === challenge) return;
    this.challenge = challenge;
    this.notify();
  }
}

export default new RevokeChallengeSubject();
