import moment from "moment";
import { TCandidateExperience, TCandidate } from "#types/candidate";
import { isEmpty, isNull, isUndefined } from "lodash";
import { isNotNumber } from "ramda-adjunct";
import { MAX_LENGHT } from "@constantsinputLength.constant";

export const experienceValidator = (experience: TCandidateExperience) => {
  if (isEmpty(experience)) return true;
  let isError = false;
  let { company, position, description } = experience;
  const { startDate, endDate, atPresent, occupationId, level, cityId } = experience;
  company ??= "";
  position ??= "";
  description ??= "";

  if (company.length > MAX_LENGHT.inputText || company.length < 1) isError = true;
  if (position.length > MAX_LENGHT.inputText || position.length < 1) isError = true;
  let diff = moment().diff(moment(startDate), "months");
  if (diff < 0) isError = true;
  diff = moment().diff(moment(endDate), "days");
  if (diff < 0) isError = true;
  if (!isEmpty(description) && description.length > MAX_LENGHT.textArea) isError = true;
  if (isNotNumber(occupationId)) isError = true;
  if (isNotNumber(level)) isError = true;
  if (isNotNumber(cityId)) isError = true;
  if (startDate && endDate) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const diff = endDateMoment.diff(startDateMoment, "months");
    if (diff <= 0) isError = true;
  }
  if (endDate && atPresent) isError = true;
  if (!endDate && !atPresent) isError = true;
  return isError;
};

export const experiencesValidation = (candidate: TCandidate) => {
  const withoutExperiencesValidation =
    !(isNull(candidate.withoutExperience) || isUndefined(candidate.withoutExperience)) &&
    candidate.withoutExperience === true;
  if (withoutExperiencesValidation) return withoutExperiencesValidation;
  return (
    !isEmpty(candidate.experiences) &&
    candidate?.experiences?.every((exp) => Boolean(exp.id)) &&
    //if any experience has correct shape.
    candidate?.experiences?.some((exp) => !experienceValidator(exp))
  );
};
