import { TEndpoint } from "#typesapi.core";
import { TResponseAllBooks } from "#typesoverdrive";
import Api from "@coreapi.core";
import getConfig from "next/config";
import { AxiosRequestConfig } from "axios";
import { mergeRight } from "ramda";

const { profileApiHost } = getConfig().publicRuntimeConfig;

export class OverdriveRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    overdrive: {
      allBooks: "/v2/overdrive/books/all",
      book: "/v2/overdrive/book/${id}"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: profileApiHost }, baseOptions));
  }

  async getAllBooks(): Promise<TResponseAllBooks[]> {
    return this.get<TResponseAllBooks[]>(this.endpoints.overdrive.allBooks, {})
      .then((response) => response.data)
      .catch(() => []);
  }
}

export default new OverdriveRepository();
