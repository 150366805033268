import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "#types/api.core";
import Api from "@core/api.core";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { lambdaWidgetsHost } = getConfig().publicRuntimeConfig;

export class WidgetRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    scripts: {
      signUp: "/lambda/signup-widget.json",
      profile: "/lambda/profile-widget.json"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: lambdaWidgetsHost }, baseOptions));
  }

  async getProfileScripts(): Promise<{ payloadScript: string; payloadStyle: string }> {
    return this.get<{ payloadScript: string; payloadStyle: string }>(this.endpoints.scripts.profile).then(
      (response) => response.data
    );
  }

  async getSignUpScripts(): Promise<{ payloadScript: string; payloadStyle: string }> {
    return this.get<{ payloadScript: string; payloadStyle: string }>(this.endpoints.scripts.signUp).then(
      (response) => response.data
    );
  }
}

export default new WidgetRepository();
