import { isNotNumber } from "ramda-adjunct";
import moment from "moment";
import { TCandidateStudy, TCandidate } from "#types/candidate.d";
import { isEmpty, isNull, isUndefined } from "lodash";

export const studyValidator = (study: TCandidateStudy) => {
  if (isEmpty(study)) return true;
  let isError = false;
  let { institute, title, profession } = study;
  const { startDate, endDate, inProgress, countryId, studyType } = study;
  institute ??= "";
  title ??= "";
  profession ??= "";
  if (isNotNumber(countryId)) isError = true;
  if (isNotNumber(studyType)) isError = true;
  if (profession.length > 120 || profession.length < 1) isError = true;
  if (institute.length > 120 || institute.length < 1) isError = true;
  if (title.length > 120 || title.length < 1) isError = true;
  let diff = moment().diff(moment(startDate), "months");
  if (diff < 0) isError = true;
  diff = moment().diff(moment(endDate), "days");
  if (diff < 0) isError = true;

  if (startDate && endDate) {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const diff = endDateMoment.diff(startDateMoment, "months");
    if (diff < 0) isError = true;
  }
  if (endDate && inProgress) isError = true;
  if (!endDate && !inProgress) isError = true;
  return isError;
};

export const studiesValidation = (candidate: TCandidate) => {
  const withoutStudiesValidation =
    !(isNull(candidate.withoutStudies) || isUndefined(candidate.withoutStudies)) && candidate.withoutStudies === true;
  if (withoutStudiesValidation) return withoutStudiesValidation;
  return (
    !isEmpty(candidate.studies) &&
    candidate?.studies?.every((study) => Boolean(study.id)) &&
    //if any studie has correct shape.
    candidate?.studies?.some((study) => !studyValidator(study))
  );
};
