import { TFunction } from "next-i18next";
import { loggedUserHost } from "@constants";
import { getForwardUrl, getQueryParamByWindow } from "@utils/url";
import { EQueryAction, EQueryParams, EWidgetType } from "@enums/widgets.enum";

type TGetUrlSignIn = {
  jobSlug?: string | null;
  action?: EQueryAction | null;
  route: string;
  t: TFunction;
};

export const getUrlSignIn = ({ jobSlug, action, route, t }: TGetUrlSignIn): string => {
  const fullUrl = new URL(t<string>("routes:signIn"), loggedUserHost);
  const widgetType = getQueryParamByWindow(EQueryParams.widgetType);
  let actionSignIn = null;
  if (widgetType === EWidgetType.iframe) {
    fullUrl.searchParams.set(EQueryParams.action, EQueryAction.signIn);
    actionSignIn = EQueryAction.signIn;
  }
  const forwardUrl = getForwardUrl({ jobSlug, action, route, actionSignIn, t });
  if (forwardUrl) fullUrl.searchParams.set(EQueryParams.forwardUrl, forwardUrl);

  return fullUrl.toString();
};
