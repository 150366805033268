import { fillUrlUtms } from "@utilsurl";
import { ParsedUrlQuery } from "querystring";

export const addUtmsInAnchorTag = (html: string, queryParams: ParsedUrlQuery): string => {
  const htmlElement = document.createElement("div");
  htmlElement.innerHTML = html;
  const anchor = htmlElement.querySelector("a");
  if (!anchor) return html;

  const href = anchor.getAttribute("href");
  if (!href) return html;

  const hrefWithUtms = fillUrlUtms(href, queryParams);
  anchor.setAttribute("href", hrefWithUtms);
  return htmlElement.innerHTML;
};
