export const modulesWithSearch = [
  {
    route: "routes:jobs",
    target: "",
    redirect: false
  },
  {
    route: "routes:jobsByCompany",
    target: "",
    redirect: false
  },
  {
    route: "routes:landing",
    target: "routes:jobsByCompany",
    redirect: true
  },
  {
    route: "routes:works",
    target: "",
    redirect: false
  },
  {
    route: "routes:worksBySlug",
    target: "",
    redirect: false
  },
  {
    route: "routes:byCity",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:bySector",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:byOccupation",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:searchHit",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:jobsView",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:jobsViewByCompany",
    target: "routes:jobsByCompany",
    redirect: true
  },
  {
    route: "routes:notFound",
    target: "routes:jobs",
    redirect: true
  },
  {
    route: "routes:landingCompanies",
    target: "routes:jobs",
    redirect: true
  }
];
