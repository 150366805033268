import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AuthContext, CandidateContext, initCandidateContext } from "@context/candidate.context";
import { TCandidateCtx } from "#types/candidate";
import { CandidateRepository, JobsWithAuthRepository } from "@repositories";
import { isAvailableToApply } from "@utils/candidate";
import profileSubject, { ProfileSubject } from "@classes/profileSubject.class";
import { ProfileConcreteObserver } from "@classesprofileConcreteObserver.class";

const withCandidateHoc = <T,>(WrappedComponent: React.FC<T>) => {
  const WithCandidate: React.FC<T & JSX.IntrinsicAttributes> = (props: T & JSX.IntrinsicAttributes) => {
    const authContext = useContext(AuthContext);
    const [candidateContext, setCandidateContext] = useState<TCandidateCtx>(initCandidateContext);

    const callbackProfileComplete = useCallback((subject: ProfileSubject) => {
      if (!subject.nextAction) return;
      CandidateRepository.getProfile().then((candidate) =>
        JobsWithAuthRepository.getOriginsAndLinkups().then((originsAndLinkups) => {
          setCandidateContext({
            candidate,
            originsAndLinkups,
            availableToApply: isAvailableToApply(candidate)
          });
        })
      );
    }, []);

    const profileObserver = useMemo(() => {
      return new ProfileConcreteObserver(callbackProfileComplete);
    }, [callbackProfileComplete]);

    useEffect(() => {
      if (!authContext.isAuthenticated) return setCandidateContext(initCandidateContext);
      CandidateRepository.getProfile().then((candidate) =>
        JobsWithAuthRepository.getOriginsAndLinkups().then((originsAndLinkups) => {
          setCandidateContext({
            candidate,
            originsAndLinkups,
            availableToApply: isAvailableToApply(candidate)
          });
        })
      );
    }, [authContext.isAuthenticated, profileObserver]);

    useEffect(() => {
      profileSubject.attach(profileObserver);
      return () => {
        profileSubject.detach(profileObserver);
      };
    }, [profileObserver]);

    return (
      <CandidateContext.Provider value={candidateContext}>
        <WrappedComponent {...props} />
      </CandidateContext.Provider>
    );
  };

  WithCandidate.displayName = "WithCandidate";

  return WithCandidate;
};

export default withCandidateHoc;
