import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import authSubject, { AuthSubject } from "@classes/authSubject.class";
import { AuthConcreteObserver } from "@classes/authConcreteObserver.class";
import { AuthRepository } from "@repositories/index";
import { AuthContext, initAuthContext } from "@context/auth.context";
import AuthListener from "@components/auth/listener/wrapper";
import { ETypeAuth } from "@enums/typeAuth.enum";
import { ISession } from "#types/auth";
import { RevokeConcreteObserver } from "@classes/revokeChallenge/revokeChallengeConcreteObserver.class";
import revokeChallengeSubject, { RevokeChallengeSubject } from "@classes/revokeChallenge/revokeChallengeSubject.class";
const sessionByType = new Map<ETypeAuth, (token: string) => Promise<ISession>>();
sessionByType.set(ETypeAuth.challenge, AuthRepository.requestSessionByChallenge);
sessionByType.set(ETypeAuth.token, AuthRepository.checkSession);
sessionByType.set(ETypeAuth.refreshToken, AuthRepository.refreshSession);

const withAuthHoc = <T,>(WrappedComponent: React.FC<T>) => {
  const WithAuth: React.FC<T & JSX.IntrinsicAttributes> = (props: T & JSX.IntrinsicAttributes) => {
    const [session, setSession] = useState(initAuthContext);

    const callbackToken = useCallback(({ token, typeAuth }: AuthSubject) => {
      const fnSession = sessionByType.get(typeAuth);
      if (!token || !fnSession) return;
      fnSession.call(AuthRepository, token).then((authData) => {
        setSession((prev) => ({ ...prev, ...authData }));
      });
    }, []);

    const callbackRevokeChallenge = useCallback(({ challenge }: RevokeChallengeSubject) => {
      if (challenge) AuthRepository.revokeChallenge(challenge);
    }, []);

    const authObserver = useMemo(() => {
      return new AuthConcreteObserver(callbackToken);
    }, [callbackToken]);

    const revokeObserver = useMemo(() => {
      return new RevokeConcreteObserver(callbackRevokeChallenge);
    }, [callbackRevokeChallenge]);

    useEffect(() => {
      authSubject.attach(authObserver);
      revokeChallengeSubject.attach(revokeObserver);
      return () => {
        authSubject.detach(authObserver);
        revokeChallengeSubject.detach(revokeObserver);
      };
    }, [authObserver, revokeObserver]);

    return (
      <Fragment>
        <AuthContext.Provider value={{ ...session }}>
          <WrappedComponent {...props} />
          <AuthListener />
        </AuthContext.Provider>
      </Fragment>
    );
  };

  WithAuth.displayName = "WithAuthentication";

  return WithAuth;
};

export default withAuthHoc;
