import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";
import { TEndpoint } from "#types/api.core";
import { TByCategorySlug, TByType, TVacanciesByCityOcupacol } from "#types/searchHit";

const { seoApiHost } = getConfig().publicRuntimeConfig;

export class SearchHitRepository extends Api {
  //TODO: move to config file
  protected readonly endpoints: TEndpoint = {
    landing: {
      byType: "/v1/landing/list/{type}",
      bySlug: "/v1/landing/{slug}",
      bySlugOcupacol: "v1/landing/list/by-occupation-with-location/{parentSlug}"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: seoApiHost }, baseOptions));
  }

  async getInfoByType(type: string): Promise<TByType[]> {
    return this.get<TByType[]>(this.endpoints.landing.byType, { params: { type } }).then((response) => response.data);
  }

  async getByOcupacolSlug(parentSlug: string): Promise<TVacanciesByCityOcupacol> {
    return this.get<TVacanciesByCityOcupacol>(this.endpoints.landing.bySlugOcupacol, { params: { parentSlug } }).then(
      (response) => response.data
    );
  }

  getBySlug(slug: string): Promise<TByCategorySlug> {
    return this.get<TByCategorySlug>(this.endpoints.landing.bySlug, { params: { slug } }).then(
      (response) => response.data
    );
  }
}

export default new SearchHitRepository();
