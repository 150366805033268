import { fullBasePath } from "@constants";
import { TInternalLinking } from "#typesschemas";

export const INTERNAL_LINKING: TInternalLinking[] = [
  {
    tag: "Empleos en Medellín ",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-medellin`
  },
  {
    tag: "Empleo en Bogotá",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-bogota`
  },
  {
    tag: "Empleo en Barranquilla",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-barranquilla`
  },
  {
    tag: "Empleo en Cartagena",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-cartagena`
  },
  {
    tag: "Empleo en Cali",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-cali`
  },
  {
    tag: "Empleo en Cúcuta",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-cucuta`
  },
  {
    tag: "Empleo en Pereira",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-pereira`
  },
  {
    tag: "Empleo en Bucaramanga",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-bucaramanga`
  },
  {
    tag: "Empleos en Santa Marta",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-santa-marta`
  },
  {
    tag: "Empleos en Valledupar",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-valledupar`
  },
  {
    tag: "Empleos en Manizales",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-manizales`
  },
  {
    tag: "Empleos en Pasto",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-pasto`
  },
  {
    tag: "Empleos en Palmira",
    url: `${fullBasePath}/trabajos/ofertas-empleo-en-palmira`
  },
  {
    tag: "Administrativo auxiliar",
    url: `${fullBasePath}/trabajos/ofertas-empleo-de-auxiliar-administrativo`
  },
  {
    tag: "SENA empleos",
    url: `${fullBasePath}/trabajos/empleo-sena`
  },
  {
    tag: "Trabajos desde casa",
    url: `${fullBasePath}/trabajos/ofertas-trabajos-desde-casa`
  },
  {
    tag: "Servicio de empleo",
    url: `${fullBasePath}/trabajos/servicio-de-empleo`
  },
  {
    tag: "Bolsa de empleo",
    url: `${fullBasePath}/trabajos/bolsa-de-empleo`
  },
  {
    tag: "Empleos sin experiencia",
    url: `${fullBasePath}/trabajos/ofertas-empleo-sin-experiencia`
  },
  {
    tag: "Trabajo sin experiencia Bogotá",
    url: `${fullBasePath}/trabajos/empleo-sin-experiencia-bogota`
  },
  {
    tag: "Trabajo en remoto",
    url: `${fullBasePath}/trabajos/ofertas-empleo-trabajo-remoto`
  },
  {
    tag: "Ofertas de empleo",
    url: `${fullBasePath}/empleos`
  },
  {
    tag: "Trabajo sin experiencia Cali",
    url: `${fullBasePath}/trabajos/empleo-sin-experiencia-cali`
  },
  {
    tag: "Trabajos fines de semana",
    url: `${fullBasePath}/trabajos/trabajo-fines-de-semana`
  },
  {
    tag: "Trabajo sin experiencia Barranquilla",
    url: `${fullBasePath}/trabajos/trabajo-sin-experiencia-barranquilla`
  },
  {
    tag: "Contrato término indefinido",
    url: `${fullBasePath}/trabajos/ofertas-empleo-contrato-termino-indefinido`
  },
  {
    tag: "Servicio al cliente",
    url: `${fullBasePath}/trabajos/vacantes-servicio-al-cliente`
  }
];
