import { TReferredContext } from "#types/referred";
import { createContext } from "react";

export const initReferredContext: TReferredContext = {
  referralSites: null,
  referredConfig: null,
  referralList: [],
  cities: {
    totalRows: 0,
    rows: [],
    counters: []
  },
  citiesSelected: [],
  countries: {
    rows: [],
    current: {
      code: "",
      name: "",
      id: null,
      flagLink: ""
    }
  },
  vacancies: [],
  currentReferredInfo: {
    firstName: "",
    identificationNumber: "",
    countryCode: "",
    comments: "",
    email: "",
    lastName: "",
    phone: "",
    cityBirth: NaN,
    vacancies: [],
    upload: "",
    index: NaN
  },
  documentTypes: [],
  loading: false,
  setContextValue: () => (value: TReferredContext) => value
};

export const ReferredContext = createContext<TReferredContext>(initReferredContext);

ReferredContext.displayName = "ReferredContext";
