import getConfig from "next/config";

export const GTM_ID = getConfig().publicRuntimeConfig.gtmId || "GTM-5RRT3W2";
export const GTM_PAYLOAD_IFRAME = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
export const GTM_DATA_LAYER = `window.dataLayer = window.dataLayer || [];`;
export const GTM_AUTH_SCRIPT = `window.dataLayer.push(authObj);`;
export const GTM_PAYLOAD_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='gtm';j.defer=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;if(!document.getElementById('gtm')){f.parentNode.insertBefore(j,f);}
})(window,document,'script','dataLayer','${GTM_ID}');`.replace(/\n/gm, "");
