import getConfig from "next/config";
import { mergeRight } from "ramda";
import { AxiosRequestConfig } from "axios";

import Api from "@core/api.core";
import { TEndpoint } from "#types/api.core";
import { TDeclineInvitation } from "#types/matcher";

const { jobsApiHost } = getConfig().publicRuntimeConfig;

export class MatcherRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    matcher: {
      declineInvitation: "/v1/matcher/decline"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
  }

  async declineInvitation(body: TDeclineInvitation): Promise<TDeclineInvitation> {
    return this.post<TDeclineInvitation>(this.endpoints.matcher.declineInvitation, body).then((response) => {
      return response.data;
    });
  }
}

export default new MatcherRepository();
