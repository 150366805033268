import { TLandingContext } from "#types/landing";
import { createContext } from "react";

export const initLandingContext: TLandingContext = {
  countries: [],
  landingPage: null,
  showMenu: false,
  setContextValue: () => (value: TLandingContext) => value,
  settingsJobSite: null,
  serverCompanySlug: undefined
};

export const LandingContext = createContext<TLandingContext>(initLandingContext);

LandingContext.displayName = "LandingContext";
