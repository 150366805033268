export const headerLogin = {
  height: "89px"
};

export const headerLogout = {
  height: "113px"
};

export const headerLoginJobs = {
  height: "89px"
};

export const headerLogoutJobs = {
  height: "89px"
};
