import { IObserver } from "@interfaces/observer.interface";
import { ISubject } from "@interfaces/subject.interface";
import { ProfileSubject } from "@classes/profileSubject.class";

export class ProfileConcreteObserver implements IObserver {
  constructor(private readonly callback: (subject: ProfileSubject) => void) {}
  update(subject: ISubject): void {
    if (!(subject instanceof ProfileSubject)) return;
    this.callback(subject);
  }
}
