import { studiesValidation } from "./../validations/candidate/studies.validation";
import { experiencesValidation } from "./../validations/candidate/experiences.validation";
import { TCandidate } from "#types/candidate";
import { propertiesWithValueCo, propertiesWithValueMx } from "@constants/propertiesWithValue.constant";

const propertiesValidations = (candidate: TCandidate, properties: (keyof TCandidate)[]) => {
  const isAvailable = true;
  for (const property of properties) {
    if (candidate[property] !== null && isAvailable) continue;
    return false;
  }
  return true;
};

const COValidationsToApply = (candidate: TCandidate) => {
  let isAvailable = true;
  isAvailable = propertiesValidations(candidate, propertiesWithValueCo);
  if (!isAvailable) return false;
  if (!experiencesValidation(candidate)) isAvailable = false;
  else if (!studiesValidation(candidate)) isAvailable = false;
  else if (!candidate.address) isAvailable = false;
  return isAvailable;
};

const MXValidationsToApply = (candidate: TCandidate) => {
  let isAvailable = true;
  isAvailable = propertiesValidations(candidate, propertiesWithValueMx);
  if (!isAvailable) return false;
  return isAvailable;
};

const registeredFactories: Record<string, (candidate: TCandidate) => boolean> = {
  "es-MX": MXValidationsToApply,
  "es-CO": COValidationsToApply
};

const validationFactory = (factoryName: string) => {
  if (!(factoryName in registeredFactories)) return COValidationsToApply;
  return registeredFactories[factoryName];
};

export const isAvailableToApply = (candidate: TCandidate): boolean => {
  const candidateLang = `${candidate.country?.language}-${candidate.country?.iso2}`;
  const validation = validationFactory(candidateLang);
  return validation(candidate);
};
