import { createContext } from "react";
import { ISession } from "#types/auth";

export const initAuthContext: ISession = {
  user: null,
  isAuthenticated: false
};

export const AuthContext = createContext<ISession>(initAuthContext);

AuthContext.displayName = "AuthContext";
