import { TInternalLinking } from "#typesschemas";
import { INTERNAL_LINKING } from "@schemasinternalLinking/linkingList";

export class InternalLinkingRepository {
  async getInternalLinks(): Promise<TInternalLinking[]> {
    return Promise.resolve(INTERNAL_LINKING);
  }
}

export default new InternalLinkingRepository();
