export enum ESalaryType {
  hour = "HOUR",
  day = "DAY",
  week = "WEEK",
  month = "MONTH",
  year = "YEAR"
}

export enum EEmploymentType {
  fixed_term = "FIXED_TERM",
  full_time = "FULL_TIME",
  part_time = "PART_TIME",
  contractor = "CONTRACTOR",
  temporary = "TEMPORARY",
  intern = "INTERN",
  volunteer = "VOLUNTEER",
  per_diem = "PER_DIEM",
  internship = "INTERNSHIP",
  other = "OTHER",
  unknown = "unknown"
}

export enum EEducationLevels {
  high_school = "high school",
  associate_degree = "associate degree",
  bachelor_degree = "bachelor degree",
  professional_certificate = "professional certificate",
  postgraduate_degree = "postgraduate degree"
}
