export const settingsHeader = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  draggable: true,
  autoplay: true
};

export const settingsVideoOptions = {
  autoplay: true,
  controls: true,
  fluid: true,
  preload: "none",
  aspectRatio: "16:9",
  src: "",
  poster: undefined
};

export const NEXT_ARROW = "next";
export const PREV_ARROW = "prev";

export const assetsType = {
  video: "video",
  image: "image"
};
