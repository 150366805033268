import { TFunction } from "next-i18next";
import { getForwardUrl } from "@utils/url";
import { EQueryAction, EQueryParams } from "@enums/queryParams.enum";

type ForWardProps = {
  jobSlug?: string;
  companySlug?: string;
  action?: EQueryAction | null;
  route: string;
  t: TFunction;
};

export const setUrlState = ({ companySlug, jobSlug, action, route, t }: ForWardProps) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(EQueryParams.forwardUrl, getForwardUrl({ jobSlug, action, route, t }));
  !searchParams.has(EQueryParams.utmSource) && companySlug && searchParams.set(EQueryParams.companyId, companySlug);
  const search = searchParams.toString();
  window.history.replaceState(null, window.document.title, `?${search}`);
};
