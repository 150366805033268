import { TAlternateTags, TRoutesTanslate } from "#typesmetaTags";

export const localesTags: TAlternateTags[] = [
  {
    locale: "en-US",
    mainPathLocale: "/us"
  },
  {
    locale: "es-AR",
    mainPathLocale: "/ar"
  },
  {
    locale: "es-CO",
    mainPathLocale: "/co"
  },
  {
    locale: "es-CR",
    mainPathLocale: "/cr"
  },
  {
    locale: "es-DO",
    mainPathLocale: "/do"
  },
  {
    locale: "es-EC",
    mainPathLocale: "/ec"
  },
  {
    locale: "es-MX",
    mainPathLocale: "/mx"
  },
  {
    locale: "es-NI",
    mainPathLocale: "/ni"
  },
  {
    locale: "es-SV",
    mainPathLocale: "/sv"
  },
  {
    locale: "pt-BR",
    mainPathLocale: "/br"
  },
  {
    locale: "x-default",
    mainPathLocale: "/co"
  }
];

export const routesTranslate: TRoutesTanslate = {
  empleosus: "/jobs",
  empresasus: "/companies",
  trabajosus: "/works",
  empleosbr: "/jobs",
  empresasbr: "/companies",
  trabajosbr: "/works"
};
