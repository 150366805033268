import React, { useEffect, useState } from "react";
import { UserBackToken } from "../../constants";
import UserBackImage from "@public/assets/icons/user-back.svg";
import userBackRepository from "../../repositories/userBack.repository";
import styles from "./buttonUserBack.module.scss";
import Image from "next/image";
import { loadSdk } from "@utils/userBack/loadSdk.util";
import { TFunction, withTranslation } from "next-i18next";

const blockClassName = "button-user-back";
type Props = {
  t: TFunction;
};
const ButtonUserBack: React.FC<Props> = ({ t }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkIsLoaded = setInterval(() => {
      if (window.Userback && typeof window.Userback.isLoaded === "function" && window.Userback.isLoaded()) {
        clearInterval(checkIsLoaded);
        window.Userback.open();
        setShowButton(false);
      }
    }, 1000);

    return () => clearInterval(checkIsLoaded);
  }, []);

  setTimeout(() => setShowButton(true), 10000);

  const onClick = async () => {
    window.Userback = window.Userback || {};

    // eslint-disable-next-line camelcase
    window.Userback.access_token = UserBackToken;
    try {
      const res = await userBackRepository.getUserBackScript();
      await loadSdk(res, "magneto.userback");
    } catch (error) {
      console.error("Error on UserBack Button", error);
    }
  };
  return (
    <>
      {showButton && (
        <div className={styles[blockClassName]} onClick={onClick} title={t<string>("userBack.solutionTooltip")}>
          <Image className={styles[`${blockClassName}__icon`]} src={UserBackImage} alt="userback-button" />
        </div>
      )}
    </>
  );
};

export default withTranslation("translation")(ButtonUserBack);
