export const capitalizeAll = (text?: string | null): string => {
  if (typeof text !== "string" || !text.length) return "";
  const words = text.split(" ");
  return words.map((word) => capitalize(word)).join(" ");
};

export const capitalize = (text?: string | null): string => {
  if (typeof text !== "string" || !text.length) return "";
  const [first, ...next] = text.split("");
  return `${first.toUpperCase()}${next.join("").toLowerCase()}`;
};
