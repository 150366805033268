export enum EWidgetType {
  "iframe" = "iframe"
}

export enum EWidgetModes {
  apply = "apply",
  save = "save",
  default = "default"
}

export enum EWidgetEvents {
  changeMode = "changeMode"
}
