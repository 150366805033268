export enum EFilterOperators {
  YES = 1,
  NOT = 0
}

export enum EDeviceTypes {
  mobile = "mobile",
  desktop = "desktop"
}

export enum ESortTypes {
  ASC = "ASC",
  DSC = "DESC"
}

export enum EFilterRepositoryTypes {
  api = "endPoint",
  orm = "storage",
  session = "session",
  valueList = "valueList",
  inputValue = "inputValue",
  factory = "customFactory"
}

export enum ETypeFilters {
  equal = "EQUAL",
  range = "RANGE",
  match = "MATCH",
  geoPoint = "GEO_POINT",
  search = "SEARCH"
}

export enum ERenderType {
  selectionList = "SELECTION_LIST",
  search = "SEARCH",
  multiSelect = "MULTI_SELECT",
  check = "CHECK",
  geoPoint = "GEO_POINT",
  customByCompany = "CUSTOM:BY_COMPANY"
}

export enum EDataType {
  number = "number",
  date = "date",
  string = "string",
  boolean = "boolean"
}

export enum EFilterPipe {
  ageRange = "scriptAgeStrategy",
  operatorInline = "operatorInline"
}

export enum ERenderChild {
  noRender = "NOT_RENDER",
  disabled = "DISABLED"
}
