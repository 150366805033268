import { TCandidate } from "#typescandidate";

export const propertiesWithValueMx: Array<keyof TCandidate> = ["firstName", "lastName", "email"];

export const propertiesWithValueCo: Array<keyof TCandidate> = [
  "firstName",
  "lastName",
  "email",
  "cityBirthId",
  "birthdate",
  "phone",
  "latitude",
  "longitude",
  "countryCode",
  "cityId",
  "sex",
  "gender",
  "titleOrProfession",
  "salary",
  "salaryFrequencyId",
  "currencyPayId",
  "identificationNumber",
  "identificationType",
  "civilStatus"
];
