import { primitiveValues } from "./primitiveValues.util";

export const decoder = (value: string) => {
  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value);
  }
  if (value in primitiveValues) {
    return primitiveValues[value];
  }
  return decodeURI(value);
};
