export const extractRouteFromRouter = (str: string) => {
  const regex = /\/([^/]+)|(\/[^/]+\/?)/;
  const match = str.match(regex);

  if (match && (match[1] || match[2])) {
    return match[1] || match[2];
  } else {
    return null;
  }
};
