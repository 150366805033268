import { createContext } from "react";
import { TCandidateCtx } from "#types/candidate";

export const initCandidateContext: TCandidateCtx = {
  candidate: null,
  originsAndLinkups: null,
  availableToApply: false
};

export const CandidateContext = createContext<TCandidateCtx>(initCandidateContext);

CandidateContext.displayName = "CandidateContext";
