import { IObserver } from "@interfaces/observer.interface";
import { ISubject } from "@interfaces/subject.interface";
import { RevokeChallengeSubject } from "@classesrevokeChallenge/revokeChallengeSubject.class";

export class RevokeConcreteObserver implements IObserver {
  constructor(private readonly callback: (subject: RevokeChallengeSubject) => void) {}
  update(subject: ISubject): void {
    if (!(subject instanceof RevokeChallengeSubject)) return;
    this.callback(subject);
  }
}
