import { isValidUrl } from "./fillUrlUtms.util";

type Props = {
  url: string;
  key: string;
  value: string;
};

export const setQueryParam = ({ url, key, value }: Props) => {
  if (!isValidUrl(url) || !key || !value) return undefined;
  const fullUrl = new URL(url);
  fullUrl.searchParams.set(key, value);
  return fullUrl.toString();
};
