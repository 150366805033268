import { ParsedUrlQuery } from "querystring";
import { isClientSide } from "@constants/environment.constant";

export const getQueyParams = (key: string, query: ParsedUrlQuery) => {
  if (isClientSide) {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  }
  return query[key];
};
