import getConfig from "next/config";
import { mergeRight } from "ramda";
import { AxiosRequestConfig } from "axios";
import Api from "@core/api.core";
import { TEndpoint } from "#types/api.core";
import {
  TCityByIP,
  TReferredCities,
  TReferredContext,
  TReferredCountries,
  TReferredDocumentType,
  TReferredSendType,
  TReferredVacant
} from "#types/referred";

const { jobsApiHost, referralsApiHost } = getConfig().publicRuntimeConfig;

export class ReferredRepository extends Api {
  //TODO: move to config file
  protected readonly endpoints: TEndpoint = {
    referrals: {
      info: "/v1/referrals/settings/{companySlug}",
      sendInfo: "/v1/referrals/{slug}"
    },
    countries: {
      default: "/v1/public/countries/active",
      city: "/v1/public/countries/country/ip"
    },
    cities: {
      search: "/v1/cities/search",
      byId: "/v1/cities/label"
    },
    vacancies: {
      default: "/v1/vacancies/{companySlug}"
    },
    documents: {
      default: "/v1/document-types"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
  }

  private createReferralOptions(baseOptions: AxiosRequestConfig = {}): AxiosRequestConfig {
    return mergeRight({ baseURL: referralsApiHost }, baseOptions);
  }

  async getInfoReferred(companySlug: string): Promise<TReferredContext> {
    return this.get<TReferredContext>(this.endpoints.referrals.info, {
      params: { companySlug },
      ...this.createReferralOptions()
    }).then((response) => response.data);
  }

  async sendReferred(params: TReferredSendType) {
    return this.post(this.endpoints.referrals.sendInfo, { ...params }, this.createReferralOptions()).then(
      (response) => response.data
    );
  }

  async getCityByIp(): Promise<TCityByIP> {
    return this.get<TCityByIP>(this.endpoints.countries.city).then((response) => response.data);
  }

  async getCities(params: { term: string }): Promise<TReferredCities> {
    return this.get<TReferredCities>(this.endpoints.cities.search, { params }).then((response) => response.data);
  }

  async getCitiesById(params: { id: number }): Promise<TReferredCities> {
    return this.get<TReferredCities>(this.endpoints.cities.byId, { params }).then((response) => response.data);
  }

  async getCountries(): Promise<TReferredCountries> {
    return this.get<TReferredCountries>(this.endpoints.countries.default).then((response) => response.data);
  }

  async getVacancies(companySlug: string): Promise<TReferredVacant> {
    return this.get<TReferredVacant>(this.endpoints.vacancies.default, { params: { companySlug } }).then(
      (response) => response.data
    );
  }

  async getDocumentsTypes(): Promise<TReferredDocumentType[]> {
    return this.get<TReferredDocumentType[]>(this.endpoints.documents.default).then((response) => response.data);
  }
}

export default new ReferredRepository();
