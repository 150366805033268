export enum EQueryAction {
  "apply" = "apply",
  "signIn" = "sign-in",
  "signUp" = "sign-up",
  "save" = "save"
}

export enum EQueryParams {
  "action" = "action",
  "companyId" = "companyId",
  "detailAction" = "detailAction",
  "forwardUrl" = "forwardUrl",
  "utmSource" = "utm_source",
  "widgetType" = "widgetType",
  "linkedUp" = "linkedUp"
}
