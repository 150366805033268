import { setQueryParam } from "./setQueryParam.util";

type TParams = {
  companyName?: string | null;
  isConfidential?: boolean;
  isPrivate?: boolean | null;
  fullUrl: string;
};

export const getOfferCompanyLink = ({ companyName, isConfidential, isPrivate, fullUrl }: TParams) => {
  if (!companyName || isConfidential || isPrivate) return undefined;
  return setQueryParam({ url: fullUrl, key: "q", value: companyName.toLowerCase() });
};
