import qs from "qs";
import { decoder } from "./decoder.util";

export const stateToQueryParams = (params: Record<string, unknown>, onlyParams = false) => {
  const currentSearch = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    decoder
  });

  const newValueObj = onlyParams ? params : { ...currentSearch, ...params };
  const newValueString = qs.stringify(newValueObj, { encode: false });

  const nextSearch = newValueString ? `?${newValueString}` : "";

  window.history.replaceState(null, "", nextSearch);
};
