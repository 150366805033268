import Api from "@core/api.core";
import Axios, { AxiosRequestConfig, Canceler } from "axios";
import { mergeRight } from "ramda";
import getConfig from "next/config";
import { TFilterEqualValue, TGlobalFilterSettings, TRequestFilter } from "#types/filter.core";
import { TEndpoint } from "#types/api.core";

const { jobsApiHost } = getConfig().publicRuntimeConfig;

export class FiltersRepository extends Api {
  protected readonly filterSettings: Map<string, TGlobalFilterSettings>;
  protected findOptionsToken?: Canceler;

  // ToDo: move to config file
  protected readonly endpoints: TEndpoint = {
    filters: {
      settings: "/v2/filters/public/settings",
      onSearch: "/{source}",
      label: "/v1/public/filters/labels",
      countries: "/v1/public/countries/{companySlug}"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
    this.filterSettings = new Map();
  }

  async getFilterSettings(companySlug?: string): Promise<TGlobalFilterSettings> {
    return this.get<TGlobalFilterSettings>(this.endpoints.filters.settings, { params: { companySlug } }).then(
      (response) => response.data
    );
  }

  async findOptions(params: {
    term: string;
    field: string;
    source: string | null;
    filters?: Array<string | number>;
  }): Promise<TFilterEqualValue[]> {
    if (this.findOptionsToken) this.findOptionsToken("ABORTING_PREVIOUS_REQUEST");
    return this.get<TFilterEqualValue[]>(this.endpoints.filters.onSearch, {
      params,
      cancelToken: new Axios.CancelToken((canceler) => {
        this.findOptionsToken = canceler;
      })
    }).then((response) => response.data);
  }

  async findLabels(params: { filters: TRequestFilter }): Promise<{ [field: string]: TFilterEqualValue[] }> {
    return this.get<{ [field: string]: TFilterEqualValue[] }>(this.endpoints.filters.label, {
      params
    }).then((response) => response.data);
  }
}

export default new FiltersRepository();
