import { TFunction } from "next-i18next";
import { fullBasePath } from "@constants";
import { EQueryAction, EQueryParams } from "@enums/queryParams.enum";

export type LoginForwardProps = {
  jobSlug?: string | null;
  action?: string | null;
  actionSignIn?: EQueryAction | null;
  route: string;
  t: TFunction;
};

export const getForwardUrl = ({ jobSlug, action, actionSignIn, route, t }: LoginForwardProps): string => {
  if (route.includes("404") || route.includes("500")) {
    const homeUrl = new URL(t<string>("routes:jobs"), fullBasePath);
    return btoa(homeUrl.toString());
  }
  const forwardUrl = new URL(window.location.href);

  if (forwardUrl.searchParams.has(EQueryParams.forwardUrl)) {
    forwardUrl.searchParams.delete(EQueryParams.forwardUrl);
  }
  if (forwardUrl.searchParams.has(EQueryParams.widgetType)) {
    forwardUrl.searchParams.delete(EQueryParams.widgetType);
  }
  if (jobSlug && !forwardUrl.pathname.includes(jobSlug)) {
    forwardUrl.pathname = `${forwardUrl.pathname}/${jobSlug}`;
  }
  if (action) {
    forwardUrl.searchParams.set(EQueryParams.action, action);
  }
  if (actionSignIn) {
    forwardUrl.searchParams.set(EQueryParams.action, actionSignIn);
  }

  if (forwardUrl.searchParams.has(EQueryParams.linkedUp) && !jobSlug) {
    forwardUrl.pathname = `${forwardUrl.pathname}/empleos`;
  }

  return btoa(forwardUrl.toString());
};
