export enum EViewItemListSections {
  vitrinaSearch = "Vitrina-Search",
  vitrinaCategory = "Vitrina-Category",
  vacante = "Vacante",
  vacantePage = "Vacante_page",
  jobsiteHome = "Jobsite_{company_name}_Home",
  jobsiteVitrinaSearch = "Jobsite_{company_name}_Vitrina-Search",
  jobsiteVitrinaCategory = "Jobsite_{company_name}_Vitrina-Category",
  jobsiteVacante = "Jobsite_{company_name}_Vacante",
  jobsiteVacantePage = "Jobsite_{company_name}_Vacante_Page"
}

export enum EViewItemListRoutes {
  empleos = "empleos",
  trabajos = "trabajos",
  empresas = "empresas"
}

export enum EViewItemListName {
  vacante = "Similar jobs {company_name}",
  generalOffers = "Ofertas de empleo {query}",
  companyGeneralOffers = "Ofertas de empleo en {company}",
  enColombia = "en Colombia"
}

export enum EEventsGA4 {
  viewItemList = "view_item_list",
  addToCart = "add_to_cart",
  purchase = "purchase"
}
