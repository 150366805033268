import { TJobRecord } from "#typesjobs";
import { pathOr } from "ramda";

export const replaceRoute = (route: string, params: TJobRecord) => {
  const tagsParams = route.match(/:(\w)+/g) || [];
  for (const tag of tagsParams) {
    route = route.replace(new RegExp(tag, "g"), pathOr(tag, [tag.replace(":", "")], params));
  }
  return route;
};
