import { ISubject } from "@interfaces/subject.interface";
import { IObserver } from "@interfaces/observer.interface";
import { ETypeAuth } from "@enums/typeAuth.enum";

export class AuthSubject implements ISubject {
  public token: string | null;
  public typeAuth: ETypeAuth = ETypeAuth.token;
  private observers: IObserver[] = [];

  constructor() {
    this.token = null;
  }

  public attach(observer: IObserver): void {
    const isExist = this.observers.includes(observer);
    if (isExist) return;
    this.observers.push(observer);
  }

  public detach(observer: IObserver): void {
    const observerIndex = this.observers.indexOf(observer);
    if (observerIndex === -1) return;
    this.observers.splice(observerIndex, 1);
  }

  public notify(): void {
    for (const observer of this.observers) {
      observer.update(this);
    }
  }

  public updateToken(token: string | null, typeAuth: ETypeAuth = ETypeAuth.token): void {
    if (this.token === token) return;
    this.token = token;
    this.typeAuth = typeAuth;
    this.notify();
  }
}

export default new AuthSubject();
