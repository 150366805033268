import { createContext } from "react";
import { TSearchHitContext } from "#types/searchHit";

export const initSearchHitContext: TSearchHitContext = {
  dataByLocation: [],
  dataByType: [],
  dataByOccupationByLocation: {
    meta: {
      slug: "",
      title: ""
    },
    landings: []
  },
  category: null,
  type: null,
  setContextValue: () => (value: TSearchHitContext) => value
};
export const SearchHitContext = createContext<TSearchHitContext>(initSearchHitContext);

SearchHitContext.displayName = "SearchHitContext";
