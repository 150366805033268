import React, { Fragment, useCallback, useContext, useEffect, useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { GTM_AUTH_SCRIPT, GTM_DATA_LAYER, GTM_PAYLOAD_SCRIPT, fullBasePath } from "@constants";
import withAuthHoc from "@components/auth/listener/hoc";
import "magneto365.ui/dist/cjs/css/magneto.ui.lib.min.css";
import "@styles/antd.less";
import "@styles/globals.css";
import "@styles/line-awesome.css";
import "@styles/main.scss";
import ButtonUserBack from "@components/buttonUserBack/component";
import withCandidateHoc from "@components/candidate/hoc";
import { AuthContext } from "@contextauth.context";
import { jsonAuthDataLayer } from "@utilsgoogleTagManager";

function Magneto({ Component, pageProps }: AppProps) {
  const loadingRef = useRef<LoadingBarRef>(null);
  const { user, isAuthenticated } = useContext(AuthContext);
  const router = useRouter();

  const handleStart = useCallback(
    (url: string) => {
      if (url !== router.asPath) {
        loadingRef.current?.continuousStart();
      }
    },
    [router.asPath]
  );

  const handleComplete = useCallback(
    (url: string) => {
      if (url === `${router.basePath}${router.asPath}`) {
        loadingRef.current?.complete();
      }
    },
    [router.asPath, router.basePath]
  );

  useEffect(() => {
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [handleComplete, handleStart, router, user?.id]);

  return (
    <Fragment>
      <Head>
        <link rel="dns-prefetch" href={fullBasePath} />
        <link rel="preconnect" href="https://www.googletagmanager.com/" crossOrigin="true" />
        <script dangerouslySetInnerHTML={{ __html: GTM_DATA_LAYER }} />
        {isAuthenticated && (
          <script
            dangerouslySetInnerHTML={{
              __html: GTM_AUTH_SCRIPT.replace("authObj", JSON.stringify(jsonAuthDataLayer(user?.id as number)))
            }}
          />
        )}
        <script dangerouslySetInnerHTML={{ __html: GTM_PAYLOAD_SCRIPT }} />
        <link rel="shortcut icon" href={`${router.basePath}/favicon.ico`} />
        {/* {showAlternateTags(router.asPath) && <AlternateTags localesTags={localesTags} nextRouter={router} />} */}
        {/* <meta property="fb:app_id" content="1512397642110482" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <LoadingBar height={3} color="#00d461" ref={loadingRef} shadow={true} />
      <Component {...pageProps} />
      <ButtonUserBack />
    </Fragment>
  );
}

export default withAuthHoc(withCandidateHoc(appWithTranslation(Magneto)));
