import { stringify } from "qs";
import { i18n } from "next-i18next";
import getConfig from "next/config";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { TRejectInterceptor, TRequestInterceptor, TResponseInterceptor } from "#types/api.core";

class Api {
  protected api: AxiosInstance;

  public constructor(config: AxiosRequestConfig) {
    this.api = axios.create(config);
    this.api.interceptors.request.use(this.usePathParams);
    this.api.interceptors.request.use(this.useQueryParams);
    this.api.interceptors.request.use(this.useLanguage);
  }

  protected usePathParams(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    if (!initConfig.url) return initConfig;
    const params: Record<string, string | number> = initConfig.params || initConfig.data;
    const iterator: IterableIterator<RegExpMatchArray> = initConfig.url.matchAll(/\{(.*?)}/g);
    for (const [keyword, path] of iterator) {
      initConfig.url = initConfig.url?.replace(keyword, `${params[path]}`);
      delete params[path];
    }
    return initConfig;
  }

  protected useQueryParams(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    initConfig.paramsSerializer = (query) => stringify(query, { encodeValuesOnly: true });
    return initConfig;
  }

  protected useLanguage(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    if (initConfig.headers && initConfig.headers["Accept-Language"]) return initConfig;
    if (initConfig.headers && !initConfig.headers["Accept-Language"] && i18n?.language) {
      initConfig.headers["Accept-Language"] = i18n?.language;
    } else if (initConfig.headers && !initConfig.headers["Accept-Language"] && !i18n?.language) {
      const { mainLang } = getConfig().publicRuntimeConfig;
      initConfig.headers["Accept-Language"] = mainLang;
    }
    return initConfig;
  }

  /**
   *
   * @param config
   */
  public getUri(config?: AxiosRequestConfig): string {
    return this.api.getUri(config);
  }

  /**
   *
   * @param config
   */
  public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  /**
   *
   * @param url
   * @param config
   */
  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config);
  }

  /**
   *
   * @param url
   * @param config
   */
  public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  /**
   *
   * @param url
   * @param config
   */
  public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.head(url, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public post<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.api.post(url, data, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public put<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config);
  }

  /**
   *
   * @param url
   * @param data
   * @param config
   */
  public patch<T, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config);
  }

  /**
   *
   * @param interceptor {function}
   */
  protected addRequestInterceptor(interceptor: TRequestInterceptor) {
    this.api.interceptors.request.use(interceptor);
  }

  /**
   *
   * @param interceptor {function}
   * @param rejectInterceptor
   */
  protected addResponseInterceptor(interceptor: TResponseInterceptor, rejectInterceptor?: TRejectInterceptor) {
    this.api.interceptors.response.use(interceptor, rejectInterceptor);
  }
}

export default Api;
