import { AxiosRequestConfig } from "axios";
import { FiltersRepository } from "./filters.repository";
import { TEndpoint } from "#types/api.core";
import { isClientSide } from "@constants/environment.constant";
import { getToken } from "@utils/auth";

export class FiltersWithAuthRepository extends FiltersRepository {
  // ToDo: move to config file
  protected readonly endpoints: TEndpoint = {
    filters: {
      settings: "/v2/filters/settings",
      onSearch: "/{source}",
      label: "/v1/filters/labels",
      countries: "/v1/public/countries/{companySlug}"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(baseOptions);
    this.api.interceptors.request.use(this.useBearerToken);
  }

  private useBearerToken(initConfig: AxiosRequestConfig): AxiosRequestConfig {
    if (initConfig.headers && initConfig.headers["Authorization"]) return initConfig;
    if (isClientSide && initConfig.headers) {
      initConfig.headers["Authorization"] = `Bearer ${getToken()}`;
    }
    return initConfig;
  }
}
export default new FiltersWithAuthRepository();
