import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import { TEndpoint } from "#types/api.core";
import { TLanding } from "#types/landing";
import { TSettingsJobSite } from "#types/landing";
import { TSettingsJobSiteSearch } from "#types/filter.core";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { jobsApiHost } = getConfig().publicRuntimeConfig;

export class LandingRepository extends Api {
  // ToDo: move to config file
  protected readonly endpoints: TEndpoint = {
    landing: {
      info: "/v1/landing/{companySlug}",
      marks: "/v1/public/jobs/setting/{id}"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    super(mergeRight({ baseURL: jobsApiHost }, baseOptions));
  }

  async getInfoLanding(companySlug: string, brand?: string): Promise<TLanding> {
    return this.get<TLanding>(this.endpoints.landing.info, { params: { companySlug, brand } }).then(
      (response) => response.data
    );
  }

  async getSettingsJobSite(params: TSettingsJobSiteSearch): Promise<TSettingsJobSite> {
    return this.get<TSettingsJobSite>(this.endpoints.landing.marks, {
      params
    }).then((response) => response.data);
  }
}

export default new LandingRepository();
